/* eslint-disable camelcase */

const MAPS_LOCATION = {
  br:
    'Ei1Fc3RyYWRhIE11bi4gLSBFeHRyZW1hLCBNRywgMzc2NDAtMDAwLCBCcmF6aWwiLiosChQKEgnBCHTi11LJlBGSj2hjaVMVAxIUChIJ39NjMZurzpQRpgWxdJSw0gQ',
  global: 'ChIJOa-LYOgJxkcRYUcg-5RSGjM',
}

export default class Ga4Parser {
  constructor(state) {
    this.store = state.storeCode
    this.currency = state.currency
  }

  viewItemList(products) {
    return this._itemsParser(products)
  }

  viewCart(line_items) {
    return this._itemsParser(line_items)
  }

  viewItem(product) {
    return this._itemsParser(this._toArray(product))
  }

  addToWishlist(product) {
    return this._itemsParser(this._toArray(product))
  }

  addToCart(product) {
    return this._itemsParser(this._toArray(product))
  }

  removeFromCart(product) {
    return this._itemsParser(this._toArray(product))
  }

  beginCheckout(products) {
    return this._itemsParser(products)
  }

  viewItemListV2(products, orderData = null) {
    return {
      items: this._itemsParser(products),
      ...this._getOrderData(orderData),
    }
  }

  viewCartV2(line_items, cart = null) {
    const order = {
      ...cart,
      coupon_code: cart?.applied_promotion?.code,
    }

    return {
      items: this._itemsParser(line_items),
      ...this._getOrderData(order),
    }
  }

  viewItemV2(product, orderData = null) {
    return {
      items: this._itemsParser(this._toArray(product)),
      ...this._getOrderData(orderData),
    }
  }

  addToWishlistV2(product, orderData = null) {
    return {
      items: this._itemsParser(this._toArray(product)),
      ...this._getOrderData(orderData),
    }
  }

  addToCartV2(product, cart = null) {
    const order = {
      ...cart,
      coupon_code: cart?.applied_promotion?.code,
    }

    return {
      items: this._itemsParser(this._toArray(product)),
      ...this._getOrderData(order),
    }
  }

  removeFromCartV2(product, cart = null) {
    const order = {
      ...cart,
      coupon_code: cart?.applied_promotion?.code,
    }

    return {
      items: this._itemsParser(this._toArray(product)),
      ...this._getOrderData(order),
    }
  }

  beginCheckoutV2(products, orderData = null) {
    return {
      items: this._itemsParser(products),
      ...this._getOrderData(orderData),
    }
  }

  purchaseV2(products, orderData = null) {
    return {
      items: this._itemsParser(products),
      ...this._getOrderData(orderData),
    }
  }

  _sanitize(value, removeDashes) {
    if (!value) return undefined
    const val = String(value)
    let sanitized = val.normalize('NFD').replace(/[\u0300-\u036F]/g, '')

    if (removeDashes) {
      sanitized = sanitized.replace(/-/g, '')
    }

    sanitized = sanitized.trim()
    return sanitized || undefined
  }

  _sanitizeNumber(value) {
    const num = Number(value)
    return isNaN(num) || num === 0 ? 0 : Math.abs(num)
  }

  _getPaymentMethodId(paymentInfo) {
    if (!paymentInfo || !paymentInfo[0] || !paymentInfo[0].name) {
      return undefined
    }

    const name = paymentInfo[0].name.toLowerCase()

    if (name.includes('pix')) {
      return 'pix'
    }
    if (name.includes('credito') || name.includes('crédito')) {
      return 'credit_card'
    }
    if (name.includes('boleto')) {
      return 'boleto'
    }
    if (name.includes('paypal')) {
      return 'paypal'
    }
    return undefined
  }

  _calculateOrderDiscount(adjustmentTotal) {
    if (adjustmentTotal && adjustmentTotal.length) {
      const sumTotalDiscount = adjustmentTotal.reduce((sum, adjustment) => {
        const total = Number(adjustment?.total) || 0
        return sum + total
      }, 0)
      return this._sanitizeNumber(sumTotalDiscount)
    }

    return 0
  }

  _getOrderData(orderData) {
    if (!orderData) return {}

    return {
      transaction_id: this._sanitize(orderData.number),
      value: this._sanitizeNumber(orderData.total),
      shipping: this._sanitizeNumber(orderData.shipment_total),
      currency: this.currency,
      payment_type: this._getPaymentMethodId(orderData.payment_info),
      shipping_tier: this._sanitize(orderData.shipping_info?.delivery_name),
      coupon: this._sanitize(orderData.coupon_code),
      discount: this._calculateOrderDiscount(orderData.adjustment_total),
    }
  }

  // PRIVATE

  _toArray(product) {
    return [product]
  }

  _itemsParser(products) {
    return products
      .map(this._itemAttributesSetter)
      .map((prod) => this._additionalItemAttributes(prod, this))
  }

  _additionalItemAttributes(item, _this) {
    const { currency, store } = _this

    if (!item.promotion_id) {
      delete item.promotion_id
      delete item.promotion_name
    }

    item.currency = currency
    item.location_id = MAPS_LOCATION[store]

    return item
  }

  _itemAttributesSetter(item, index) {
    const priceDiff = item.old_price
      ? Number(Number(item.old_price) - Number(item.price))
      : 0

    const discount = priceDiff > 0 ? Number(priceDiff.toFixed(2)) : 0

    return {
      item_id: item.product_id ? String(item.product_id) : '',
      item_name: item.seo_optimized_name || item.name,
      discount,
      promotion_id: item.promotion?.promotion_event_id,
      promotion_name: item.promotion?.promotion_event_id
        ? 'Promotion Event'
        : null,
      index,
      item_brand: 'Gocase',
      item_category: item.product_type_code,
      item_variant: item.case_device_id
        ? String(item.case_device_id)
        : undefined,
      price: Number(item.price),
      quantity: item.quantity || 1,
    }
  }
}
